<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit {{title}}</b>
      <b v-else>Create {{title}}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Driver</label>
        <multiselect
          class="selectExample"
          v-model="personalDriver.selected"
          :options="personalDriver.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="name"
          track-by="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="personalDriver.selected == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Source</label>
        <multiselect
          v-model="source.selected"
          :options="source.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          placeholder=" Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="source.selected == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">License Type</label>
        <multiselect
          v-model="licenceType.selected"
          :options="licenceType.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          placeholder=" Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="licenceType.selected == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="License Number"
          v-validate="'required'"
          name="License Number"
          v-model="data.licence_number"
        />
        <span class="text-danger text-sm" v-show="errors.has('License Number')">{{ errors.first('License Number') }}</span>
      </div>
    </div>
    
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="License Validity"
          name="License Validity"
          v-model="data.licence_validity"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Status</label>
          <br />
          <ul class="centerx">
            <li>
              <vs-radio v-model="data.status" vs-value="Active"
                >Active</vs-radio
              >&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <vs-radio v-model="data.status" vs-value="Inactive"
                >Inactive</vs-radio
              >
            </li>
          </ul>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
//import PaymentMethod from "./PaymentMethod";
export default {
  components: {
    //PaymentMethod
  },
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    },
    title: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          licence_number: "",
          licence_validity: "",
          status: "Active",
        },
        personalDriver: {
          options: [],
          selected: null
        },
        licenceType: {
          options: ["A", "B1", "B2", "C"],
          selected: "A"
        },
        source: {
          options: ["Internal", "External"],
          selected: "Internal"
        },
        status_submit: false,
      };
    },
    handleSubmit() {
      this.status_submit = true
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        personal_id: this.personalDriver.selected.id,
        source: this.source.selected,
        licence_type: this.licenceType.selected,
        licence_number: this.data.licence_number,
        licence_validity: this.data.licence_validity,
        status: this.data.status,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.personalDriver.selected = resp.data.personal_id;
            this.source.selected = resp.data.source;
            this.licenceType.selected = resp.data.licence_type;
            this.data.licence_number = resp.data.licence_number;
            this.data.licence_validity = resp.data.licence_validity;
            this.status.selected = resp.data.status;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
     getOptionDriver() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/user-driver", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.personalDriver.options = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get driver option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.getOptionDriver();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    }
  }
};
</script>
<style >
</style>
